<template>
<div>
    <div class="mx-4 mb-3">
        <el-scrollbar height="280px" class="w-full rounded-lg bg-white">
            <div v-for="exam in exams" :key="exam.title"  class="flex justify-between items-center border rounded-md p-2 my-1 mx-4 transition duration-500 transform-gpu hover:scale-105 hover:bg-red-50 hover:border-red-400">
                <div class="flex flex-row items-center">
                    <div class="w-32">
                        <span>{{ exam.name }}</span>
                    </div>
                    <span class="text-sm sm:mx-10 hidden sm:block">{{ exam.questions}}  questions </span>
                </div>

                <div class="flex item-center justify-center">
                    <div @click="openUpdateExam(exam.id,exam.name,exam.year)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>
                    </div>
                    <div @click="openDeleteExam(exam.id,exam.name)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                      <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>

    <ul class="relative z-0 inline-flex rounded-md -space-x-px w-full justify-center ">
        <li>
            <button :disabled="prevp === null" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                @click.prevent="prevPage"
            >
                <span class="sr-only">Previous</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </button>
        </li>
        <li v-for="page in lastp" :key="page">
            <button class=" hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                :class=" page === currentp ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"
                @click.prevent="refine(page)"
                >
                    {{ page }}
            </button>
        </li>

        <li>
            <button class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                @click.prevent="nextPage"
            >
                <span class="sr-only">Next</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
            </button>
        </li>
    </ul>
</div> 
</template>

<script>
export default {

    data () {
        return {
            
        }
    },

    props: {
        exams: Array,
        lastp: Number,
        currentp: Number,
        nextPage:Function,
        prevPage:Function,
        refine: Function
    },

    methods: {
        openDeleteExam(id,name){
            const exam = {
                id : id,
                name : name
            }
            this.$emit("selected-exam", exam);
        },
        openUpdateExam(id,name,year){
            const exam = {
                id : id,
                name : name,
                year: year
            }
            this.$emit("update-exam", exam);

        }
    }

}
</script>

<style>

</style>